var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    [
      _c("h2", [_vm._v(_vm._s(_vm.title))]),
      _c(
        "app-form",
        {
          attrs: {
            id: "room-form",
            submitAction: _vm.create,
            submitSuccessAction: _vm.createSuccess,
            validationOptions: _vm.validationOptions
          }
        },
        [
          _c(
            "div",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: _vm.hasMultipleOffices,
                  expression: "hasMultipleOffices"
                }
              ],
              staticClass: "form-group"
            },
            [
              _c("app-dropdownlist", {
                attrs: {
                  id: "officeId",
                  placeholder: _vm.$t("offices.office"),
                  dataSource: _vm.offices,
                  fields: { text: "name", value: "id" }
                },
                model: {
                  value: _vm.room.officeId,
                  callback: function($$v) {
                    _vm.$set(_vm.room, "officeId", $$v)
                  },
                  expression: "room.officeId"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-textbox", {
                attrs: { id: "name", placeholder: _vm.$t("shared.name") },
                model: {
                  value: _vm.room.name,
                  callback: function($$v) {
                    _vm.$set(_vm.room, "name", $$v)
                  },
                  expression: "room.name"
                }
              })
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "form-group" },
            [
              _c("app-numerictextbox", {
                attrs: {
                  id: "capacity",
                  placeholder: _vm.$t("rooms.capacity")
                },
                model: {
                  value: _vm.room.capacity,
                  callback: function($$v) {
                    _vm.$set(_vm.room, "capacity", $$v)
                  },
                  expression: "room.capacity"
                }
              })
            ],
            1
          ),
          _c("app-form-buttons", {
            attrs: {
              addSubmitButton: true,
              submitButtonText: "shared.create",
              addCancelButton: true,
              cancelAction: _vm.cancel
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }